<template>
    <div class="page_container">
        <div class="query_header">
            <div class="opt_container">
                <el-button
                    v-if="P(1030601)"
                    class="float_left_btn"
                    type="primary"
                    plain
                    @click="onShowEditTeacher(null)"
                >
                    新建
                </el-button>
            </div>
        </div>
        <el-table :data="tableData" stripe border>
            <el-table-column align="center" type="index" label="序号" width="72" />
            <el-table-column align="center" prop="name" label="用户名" />
            <el-table-column align="center" prop="name" label="账号类型">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.virtualFlag" type="danger" effect="plain">伪直播账号</el-tag>
                    <el-tag v-else effect="plain">正常账号</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" prop="liveAccount" label="直播器账号" />

            <el-table-column align="center" label="创建时间" prop="addTime" />
            <el-table-column align="center" width="64" label="操作">
                <template slot-scope="scope">
                    <div class="mini_link">
                        <el-link v-if="P(1030601)" type="primary" @click="onShowEditTeacher(scope.row)">编辑</el-link>
                        <el-popconfirm v-if="P(1030602)" title="确定删除吗？" @confirm="onDelTeacherAccount(scope.row)">
                            <el-link type="danger" slot="reference">删除</el-link>
                        </el-popconfirm>
                    </div>
                </template>
            </el-table-column>
        </el-table>

        <EditTeacher :isShow="isShowEditTeacher" :activeItem="activeItem" @onClose="onCloseEditTeacher" />
    </div>
</template>
<script>
import EditTeacher from './components/teacher/EditTeacher.vue'
import { getCourseTeacher, delCourseTeacher } from '@/js/api/courseApi.js'

export default {
    components: { EditTeacher },
    data() {
        return {
            tableData: [],

            activeItem: null,
            isShowEditTeacher: false,
        }
    },
    created() {
        this.getCourseTeacherApi()
    },
    methods: {
        onShowEditTeacher(item) {
            this.activeItem = item
            this.isShowEditTeacher = true
        },
        onCloseEditTeacher(state) {
            this.activeItem = null
            this.isShowEditTeacher = false
            if (state) this.getCourseTeacherApi()
        },

        onDelTeacherAccount(item) {
            delCourseTeacher({ teacherNo: item.teacherNo }).then(() => {
                this.$message.success('删除成功')
                this.getCourseTeacherApi()
            })
        },

        getCourseTeacherApi() {
            getCourseTeacher({}).then((res) => {
                this.tableData = res.data
            })
        },
    },
}
</script>
