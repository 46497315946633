<template>
    <el-select
        v-model="value"
        filterable
        :multiple="multiple"
        remote
        collapse-tags
        :placeholder="placeholder || '请输入账号名称进行搜索'"
        :remote-method="getStaffListApi"
        :loading="loading"
        :clearable="clearable"
        @clear="onClear"
        @change="onChange"
    >
        <el-option-group label="默认显示20条">
            <el-option v-for="item in staffList" :key="item.id" :value="item.id" :label="item.name">
                <StaffName :staff="item" />
            </el-option>
        </el-option-group>
    </el-select>
</template>
<script>
import { getStaffList } from '@/js/api/staffApi.js'

export default {
    // <StaffSelect @change="onStaffChange" :clearable="true" :multiple="true" />
    props: ['defaultValue', 'valueData', 'multiple', 'clearable', 'placeholder'],
    watch: {
        defaultValue: function () {
            if (!this.initState) {
                this.initValue()
            }
        },
        valueData: function () {
            this.updateValue()
        },
    },
    data() {
        return {
            value: '',

            loading: false,
            staffList: [],
        }
    },
    created() {
        this.initValue()
    },
    methods: {
        initValue() {
            if (this.defaultValue) {
                getStaffList({ staffIds: [this.defaultValue] }).then((res) => {
                    this.staffList = res.data
                    this.value = this.defaultValue
                })
            } else {
                this.getStaffListApi('')
            }
            this.initState = true
        },
        updateValue() {
            if (this.valueData) {
                let _state = false
                this.staffList.forEach((item) => {
                    if (item.id === this.valueData) {
                        this.value = this.valueData
                        _state = true
                    }
                })
                if (!_state) {
                    getStaffList({ staffIds: [this.valueData] }).then((res) => {
                        this.staffList = res.data
                        this.value = this.valueData
                    })
                }
            } else {
                this.getStaffListApi('')
            }
        },
        onChange(val) {
            this.$emit('change', val)
            this.onItemChange()
        },
        onItemChange() {
            let _item = null
            this.staffList.forEach((i) => {
                if (i.id === this.value) {
                    _item = i
                }
            })
            this.$emit('itemChange', _item)
        },
        onClear() {
            this.value = ''
            this.$emit('change', this.value)
            this.onItemChange()
            this.getStaffListApi('')
        },
        getStaffListApi(query) {
            const params = { page: 1, size: 20, showPage: false }
            if (query) params.name = query
            this.loading = true
            getStaffList(params).then((res) => {
                this.loading = false
                this.staffList = res.data
            })
        },
    },
}
</script>
