<template>
    <el-dialog :title="title" :visible.sync="isShow" width="420px" @opened="onOpened" :before-close="onClose">
        <el-form v-if="formData" ref="formData" :model="formData" :rules="rules" label-width="100px">
            <el-form-item prop="name" label="姓名">
                <el-input v-model.trim="formData.name" :maxlength="32" show-word-limit placeholder="请输入姓名" />
            </el-form-item>
            <el-form-item prop="virtualFlag" label="伪直播账号">
                <el-radio-group v-model="formData.virtualFlag" size="small">
                    <el-radio-button :label="false">正常账号</el-radio-button>
                    <el-radio-button :label="true">伪直播账号</el-radio-button>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="password" label="登陆密码">
                <el-input v-model.trim="formData.password" placeholder="请输入密码" />
            </el-form-item>
            <el-form-item prop="staffId" label="关联员工">
                <StaffSelect
                    :defaultValue="formData.staffId"
                    :clearable="true"
                    @change="(val) => (formData.staffId = val)"
                />
            </el-form-item>
        </el-form>
        <span slot="footer">
            <el-button @click="onClose">取 消</el-button>
            <el-button type="primary" @click="onSubmit">确 定</el-button>
        </span>
    </el-dialog>
</template>
<script>
import StaffSelect from '@/views/components/StaffSelect'
import { saveCourseTeacher } from '@/js/api/courseApi.js'

export default {
    props: ['isShow', 'activeItem'],
    components: { StaffSelect },
    data() {
        return {
            title: '',
            formData: null,
            rules: {
                name: [{ required: true, message: '请输入姓名', trigger: 'change' }],
                virtualFlag: [{ required: true, message: '请选择', trigger: 'change' }],
            },
        }
    },
    methods: {
        onOpened() {
            this.title = '新建账号'
            let formData = {
                name: '',
                virtualFlag: false,
                password: '',
                staffId: '',
            }
            if (this.activeItem) {
                this.title = `${this.activeItem.name} - 编辑`
                formData = { ...formData, ...this.activeItem }
            }
            this.formData = formData
        },
        onClose() {
            this.formData = null
            this.$emit('onClose')
        },

        onSubmit() {
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    saveCourseTeacher(this.formData).then(() => {
                        this.$message.success('编辑成功')
                        this.$emit('onClose', true)
                    })
                }
            })
        },
    },
}
</script>
